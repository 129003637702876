import { AppConfig } from "config/AppConfig";
import { getCookie, setCookie } from "../cookieStorage";

const REFRESH_AUTH_TOKEN_STORAGE_KEY = 'refresh_auth';
const AUTH_TOKEN_STORAGE_KEY = 'auth';

/**
 * retrieve authentication JWT
 */
export const getAuthToken = (): string|null => {
    const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    if(token) {
        return JSON.parse(token);
    }

    //if remember me activated
    const cookieToken = getCookie(AUTH_TOKEN_STORAGE_KEY);
    if(cookieToken) {
        storeAuthToken(cookieToken);
        return cookieToken;
    }

    return null;
}

/**
 * retrieve refresh token
 */
export const getRefreshAuthToken = (): string|null => {
    const token = localStorage.getItem(REFRESH_AUTH_TOKEN_STORAGE_KEY);
    if(token) {
        return JSON.parse(token);
    }
    return null;
}

/**
 * store authentication JWT
 */
export const storeAuthToken = (
    token: string,
    rememberMe: boolean = false
): void => {
    //store in localStorage
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify(token));

    //if remember me, store in cookie
    if(rememberMe) {
        setCookie(AUTH_TOKEN_STORAGE_KEY, token, AppConfig.AUTH_COOKIE_EXPIRATION_TIME);
    }
}

/**
 * store refresh token
 */
export const storeRefreshAuthToken = (
    token: string,
): void => {
    localStorage.setItem(REFRESH_AUTH_TOKEN_STORAGE_KEY, JSON.stringify(token));
}

/**
 * remove auth token (sessionStorage and cookie if exists)
 */
export const destroyAuthToken = (): void => {
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_AUTH_TOKEN_STORAGE_KEY);
    setCookie(AUTH_TOKEN_STORAGE_KEY, '', -1);
}
