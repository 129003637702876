import { API_COMPANIES } from "Routes/apiRoutes";
import { destroyAuthToken, getAuthToken } from "functions/storage/auth/authTokenStorage";
import { getStoredCompanyChoice } from "functions/storage/auth/companyChoiceStorage";
import i18n from "i18n";
import { Headers } from "type/httpTypes";
import { convertParamsEntitiesToIris } from "../convertor/apiPlatformConversion/paramsEntitiesToIriConvertor";

export const prepareApiRequest = (
    method: string = 'GET',
    params: {[key: string]: any} = {},
    sendAuthToken: boolean = true
): {
    customHeaders: Headers,
    preparedParams: {[key: string]: any},
    urlParams: object
} => {
    let customHeaders: Headers = {
        "Accept": "application/ld+json",
        "Content-Type": method === 'PATCH' ? "application/merge-patch+json": "application/ld+json"
    };

    //JWT authentication
    if(sendAuthToken) {
        const token = getAuthToken();
        if(token) {
            customHeaders = {
                ...customHeaders,
                'Authorization': 'Bearer ' + token
            }
        }
    }

    //PARAMS that are always sent
    
    const companyChoice = getStoredCompanyChoice();
    if(companyChoice) {
        //add company to params of each api request
        //ignore if params has yet a company key 
        //(because sometimes we want to filter with a selected company and not with companyChoice)
        if(!Object.keys(params).includes('company') || params.company === '' || params.company === null || params.company === undefined) {
            params = {
                ...params,
                company: API_COMPANIES + '/' + companyChoice.id
            };
        }
    } else {
        //if no companyChoice redirect to login
        destroyAuthToken();
    }

    //params allways in url
    let urlParams: object = {locale: i18n.language};

    //Mandatory conversions for api platform
    //convert objects having iri to simple iri
    const preparedParams = convertParamsEntitiesToIris(params);

    return {
        customHeaders,
        preparedParams,
        urlParams
    };
}
