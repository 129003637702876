import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//Import Icons
import { generateUrl } from "functions/router/urlGenerator";
import { useMenu } from "Components/Contexts/MenuContext";
import { haveArraysCommonValue } from "functions/arrayHelpers/commonValuesChecker";

type MenuState = {
    firstLevel: string,
    secondLevel: string|null,
    thirdLevel: string|null,
    [key: string]: string|null
}

const getVisibleMenuItems = (menuItems: any): any => {

    return menuItems.filter((menuItem: any) => menuItem.isVisible)
                    .map((menuItem:any) => {
                        if(!menuItem.subItems) {
                            return menuItem;
                        }
                        return {
                            ...menuItem,
                            subItems: menuItem.subItems.filter((subItem: any) => subItem.isVisible)
                                                        .map((subItem: any) => {
                                                            if(!subItem.childItems) {
                                                                return subItem;
                                                            }
                                                            return {
                                                                ...subItem,
                                                                childItems: subItem.childItems.filter((childItem: any) => childItem.isVisible)
                                                            }
                                                        }) 
                        }
                    });
};


const Navdata = () => {
    
    const {menu} = useMenu();
    const {pathname} = useLocation();

    useEffect(() => {
        //firstLevel
        if(pathname.includes('orders')) {
            setMenuState({
                firstLevel: 'orders',
                secondLevel: null,
                thirdLevel: null
            });
        } else if(pathname.includes('proposals')) {
            setMenuState({
                firstLevel: 'proposals',
                secondLevel: null,
                thirdLevel: null,
            })
        } else if(pathname.includes('invoices')) {
            //secondLevel
            if(pathname.includes('invoices/classic_crud')) {
                setMenuState({
                    firstLevel: 'invoices',
                    secondLevel: 'classic_crud',
                    thirdLevel: null
                });
            }
            if(pathname.includes('invoices/delivery_notes_invoicing')) {
                setMenuState({
                    firstLevel: 'invoices',
                    secondLevel: 'delivery_notes_invoicing',
                    thirdLevel: null
                });
            }
        } else if(pathname.includes('delivery_notes')) {
            //secondLevel
            if(pathname.includes('delivery_notes/classic_crud')) {
                setMenuState({
                    firstLevel: 'delivery_notes',
                    secondLevel: 'classic_crud',
                    thirdLevel: null
                });
            }
            if(pathname.includes('delivery_notes/fast_validation')) {
                setMenuState({
                    firstLevel: 'delivery_notes',
                    secondLevel: 'fast_validation',
                    thirdLevel: null
                });
            }
        } else if(pathname.includes('third_parts')) {
            //secondLevel
            if(pathname.includes('third_parts/third_part_categories')) {
                setMenuState({
                    firstLevel: 'third_parts',
                    secondLevel: 'third_part_categories',
                    thirdLevel: null
                });
            }
            if(pathname.includes('third_parts/customers')) {
                setMenuState({
                    firstLevel: 'third_parts',
                    secondLevel: 'customers',
                    thirdLevel: null
                });
            }
        } else if(pathname.includes('items')) {
            //secondLevel
            if(pathname.includes('items/items')) {
                setMenuState({
                    firstLevel: 'items',
                    secondLevel: 'items_items',
                    thirdLevel: null
                });
            }
            if(pathname.includes('items/families')) {
                setMenuState({
                    firstLevel: 'items',
                    secondLevel: 'items_families',
                    thirdLevel: null
                });
            }
            if(pathname.includes('items/collections')) {
                setMenuState({
                    firstLevel: 'items',
                    secondLevel: 'items_collections',
                    thirdLevel: null
                });
            }
            if(pathname.includes('items/priceLists')) {
                setMenuState({
                    firstLevel: 'items',
                    secondLevel: 'priceLists',
                    thirdLevel: null
                });
            }
        } else if(pathname.includes('contacts')) {
            setMenuState({
                firstLevel: 'contacts',
                secondLevel: null,
                thirdLevel: null
            });
        } else if(pathname.includes('administration')) {
            //secondLevel
            if(pathname.includes('administration/users')) {
               setMenuState({
                   firstLevel: 'admin',
                   secondLevel: 'users',
                   thirdLevel: null
               });
           }
       } else if(pathname.includes('parameters')) {
            setMenuState({
                firstLevel: 'parameters',
                secondLevel: null,
                thirdLevel: null
            });
            //secondLevel
            if(pathname.includes('parameters/companies')) {
                setMenuState({
                    firstLevel: 'parameters',
                    secondLevel: 'companies',
                    thirdLevel: null
                });
            }
            if(pathname.includes('parameters/payment_terms')) {
                setMenuState({
                    firstLevel: 'parameters',
                    secondLevel: 'payment_terms',
                    thirdLevel: null
                });
            }
            if(pathname.includes('parameters/vat_rates')) {
                setMenuState({
                    firstLevel: 'parameters',
                    secondLevel: 'vat_rates',
                    thirdLevel: null
                });
            }
            if(pathname.includes('parameters/shipping_costs')) {
                setMenuState({
                    firstLevel: 'parameters',
                    secondLevel: 'shipping_costs',
                    thirdLevel: null
                });
            }
            if(pathname.includes('parameters/printers')) {
                setMenuState({
                    firstLevel: 'parameters',
                    secondLevel: 'printers',
                    thirdLevel: null
                });
            }
        } else if(pathname.includes('payments')) {
            setMenuState({
                firstLevel: 'payments',
                secondLevel: null,
                thirdLevel: null
            });
        } else {
            setMenuState({
                firstLevel: 'home',
                secondLevel: null,
                thirdLevel: null
            });
        }
    }, [pathname]);
    
    const [menuState, setMenuState] = useState<MenuState>({
        firstLevel: 'home',
        secondLevel: null,
        thirdLevel: null
    });

    const changeMenuState = (level: string, value: string|null) => {
        setMenuState(prevMenuState => ({
            ...prevMenuState,
            [level]: value
        }));
    }
    const toggleMenuState = (level: string, value: string|null) => {
        setMenuState(prevMenuState => ({
            ...prevMenuState,
            [level]: prevMenuState[level] === value ? null: value
        }));
    }

    const menuItems: any = [
        {
            label: "",
            isHeader: true,
            isVisible: true,
        },
        {
            id: "home",
            label: "dashboard",
            icon: <i className="ri ri-home-4-line"></i>,
            link: generateUrl('home'),
            click: () => changeMenuState('firstLevel', 'home'),
            active: menuState.firstLevel === 'home',
            isVisible: menu.includes('home'),
        },
        {
            id: "proposals",
            label: "proposals",
            icon: <i className="ri ri-file-list-line"></i>,
            link: generateUrl('proposals'),
            click: () => changeMenuState('firstLevel', 'proposals'),
            active: menuState.firstLevel === 'proposals',
            isVisible: menu.includes('proposals'),
        },
        {
            id: "orders",
            label: "orders",
            icon: <i className="ri ri-truck-line"></i>,
            link: generateUrl('orders'),
            click: () => changeMenuState('firstLevel', 'orders'),
            active: menuState.firstLevel === 'orders',
            isVisible: menu.includes('orders'),
        },
        {
            id: "delivery_notes",
            label: "deliveryNotes",
            icon: <i className="ri ri-article-line"></i>,
            link: "/#",
            click: (e: any) => {
                e.preventDefault();
                toggleMenuState('firstLevel', 'delivery_notes');
            },
            active: menuState.firstLevel === 'delivery_notes',
            isVisible: haveArraysCommonValue(['delivery_notes_classic_crud', 'delivery_notes_fast_validation'], menu),
            subItems: [
                {
                    id: 'delivery_notes_classic_crud',
                    label: "classic_table",
                    link: generateUrl('delivery_notes_classic_crud'),
                    click: () => changeMenuState('secondLevel', 'delivery_notes_classic_crud'),
                    active: menuState.secondLevel === 'classic_crud',
                    parentId: 'delivery_notes',
                    isVisible: menu.includes('delivery_notes_classic_crud')
                },
                {
                    id: 'delivery_notes_fast_validation',
                    label: "fast_validation",
                    link: generateUrl('delivery_notes_fast_validation'),
                    click: () => changeMenuState('secondLevel', 'delivery_notes_fast_validation'),
                    active: menuState.secondLevel === 'fast_validation',
                    parentId: 'delivery_notes',
                    isVisible: menu.includes('delivery_notes_fast_validation')
                },
            ]
        },
        {
            id: "invoices",
            label: "invoices",
            icon: <i className="ri ri-money-euro-box-line"></i>,
            link: generateUrl('invoices'),
            click: (e: any) => {
                e.preventDefault();
                toggleMenuState('firstLevel', 'invoices');
            },
            active: menuState.firstLevel === 'invoices',
            isVisible: haveArraysCommonValue(['invoices_classic_crud', 'delivery_notes_invoicing'], menu),
            subItems: [
                {
                    id: 'invoices_classic_crud',
                    label: "classic_table",
                    link: generateUrl('invoices_classic_crud'),
                    click: () => changeMenuState('secondLevel', 'classic_crud'),
                    active: menuState.secondLevel === 'classic_crud',
                    parentId: 'invoices',
                    isVisible: menu.includes('invoices_classic_crud')
                },
                {
                    id: 'delivery_notes_invoicing',
                    label: "delivery_notes_invoicing",
                    link: generateUrl('delivery_notes_invoicing'),
                    click: () => changeMenuState('secondLevel', 'delivery_notes_invoicing'),
                    active: menuState.secondLevel === 'delivery_notes_invoicing',
                    parentId: 'invoices',
                    isVisible: menu.includes('delivery_notes_invoicing')
                },
            ]
        },
        {
            id: "payments",
            label: "payments",
            icon: <i className="ri-bank-card-2-line"></i>,
            link: generateUrl('payments'),
            click: () => changeMenuState('firstLevel', 'payments'),
            active: menuState.firstLevel === 'payments',
            isVisible: menu.includes('payments')
        },
        {
            id: "items",
            label: "items",
            icon: <i className="ri ri-trophy-line"></i>,
            link: "/#",
            click: (e: any) => {
                e.preventDefault();
                toggleMenuState('firstLevel', 'items');
            },
            active: menuState.firstLevel === 'items',
            isVisible: haveArraysCommonValue(['items_families', 'items_collections'], menu),
            subItems: [
                {
                    id: 'items_families',
                    label: "families",
                    link: generateUrl('items_families'),
                    click: () => changeMenuState('secondLevel', 'items_families'),
                    active: menuState.secondLevel === 'items_families',
                    parentId: 'items',
                    isVisible: menu.includes('items_families')
                },
                {
                    id: 'items_collections',
                    label: "collections",
                    link: generateUrl('items_collections'),
                    click: (e: any) => changeMenuState('secondLevel', 'items_collections'),
                    active: menuState.secondLevel === 'items_collections',
                    parentId: 'items',
                    isVisible: menu.includes('items_collections')
                },
                {
                    id: "items_items",
                    label: "items",
                    link: generateUrl('items_items'),
                    click: () => changeMenuState('secondLevel', 'items_items'),
                    active: menuState.secondLevel === 'items_items',
                    parentId: "items",
                    isVisible: menu.includes('items_items')
                },
                {
                    id: "priceLists",
                    label: "priceLists",
                    link: generateUrl('priceLists'),
                    click: () => changeMenuState('secondLevel', 'priceLists'),
                    active: menuState.secondLevel === 'priceLists',
                    parentId: "items",
                    isVisible: menu.includes('priceLists')
                },
            ]
        },
        {
            id: "third_parts",
            label: "thirdParts",
            icon: <i className="ri ri-group-line"></i>,
            link: "/#",
            click: (e: any) => {
                e.preventDefault();
                toggleMenuState('firstLevel', 'third_parts');
            },
            active: menuState.firstLevel === 'third_parts',
            isVisible: haveArraysCommonValue(['third_part_categories', 'customers'], menu),
            subItems: [
                {
                    id: "third_part_categories",
                    label: "thirdPartCategories",
                    link: generateUrl('third_part_categories'),
                    click: () => changeMenuState('secondLevel', 'third_part_categories'),
                    active: menuState.secondLevel === 'third_part_categories',
                    parentId: "third_parts",
                    isVisible: menu.includes('third_part_categories')
                },
                {
                    id: "customers",
                    label: "customers",
                    link: generateUrl('customers'),
                    click: () => changeMenuState('secondLevel', 'customers'),
                    active: menuState.secondLevel === 'customers',
                    parentId: "third_parts",
                    isVisible: menu.includes('customers')
                },
            ]
        },
        {
            id: "contacts",
            label: "contacts",
            icon: <i className="ri ri-contacts-book-line"></i>,
            link: generateUrl('contacts'),
            click: () => changeMenuState('firstLevel', 'contacts'),
            active: menuState.firstLevel === 'contacts',
            isVisible: menu.includes('contacts')
        },
        {
            id: "administration",
            label: "administration",
            icon: <i className = "ri ri-admin-line"></i>,
            link: "/#",
            click: (e: any) => {
                e.preventDefault();
                toggleMenuState('firstLevel', 'admin');
            },
            active: menuState.firstLevel === 'admin',
            isVisible: haveArraysCommonValue(['users'], menu),
            subItems: [
                {
                    id: "users",
                    label: "users",
                    link: generateUrl('users'),
                    click: () => changeMenuState('secondLevel', 'users'),
                    active: menuState.secondLevel === 'users',
                    parentId: "admin",
                    isVisible: menu.includes('users')
                },
            ]
        },
        // {
        //     label: "",
        //     isHeader: true,
        //     isVisible: haveArraysCommonValue(['companies', 'items_families', 'items_collections'], menu)
        // },
        {
            id: "parameters",
            label: "parameters",
            icon: <i className="ri  ri-settings-5-line"></i>,
            link: "/#",
            click: (e: any) => {
                e.preventDefault();
                toggleMenuState('firstLevel', 'parameters');
            },
            active: menuState.firstLevel === 'parameters',
            isVisible: haveArraysCommonValue(['companies', 'payment_terms', 'vat_rates'], menu),
            subItems: [
                {
                    id: "companies",
                    label: "companies",
                    link: generateUrl('companies'),
                    click: () => changeMenuState('secondLevel', 'companies'),
                    active: menuState.secondLevel === 'companies',
                    parentId: "parameters",
                    isVisible: menu.includes('companies')
                },
                {
                    id: "payment_terms",
                    label: "paymentTerms",
                    link: generateUrl('payment_terms'),
                    click: () => changeMenuState('secondLevel', 'payment_terms'),
                    active: menuState.secondLevel === 'payment_terms',
                    parentId: "parameters",
                    isVisible: menu.includes('payment_terms')
                },
                {
                    id: "vat_rates",
                    label: "vatRates",
                    link: generateUrl('vat_rates'),
                    click: () => changeMenuState('secondLevel', 'vat_rates'),
                    active: menuState.secondLevel === 'vat_rates',
                    parentId: "parameters",
                    isVisible: menu.includes('vat_rates')
                },
                {
                    id: "shipping_costs",
                    label: "shippingCosts",
                    link: generateUrl('shipping_costs'),
                    click: () => changeMenuState('secondLevel', 'shipping_costs'),
                    active: menuState.secondLevel === 'shipping_costs',
                    parentId: "parameters",
                    isVisible: menu.includes('shipping_costs')
                },
                {
                    id: "printers",
                    label: "printers",
                    link: generateUrl('printers'),
                    click: () => changeMenuState('secondLevel', 'printers'),
                    active: menuState.secondLevel === 'printers',
                    parentId: "parameters",
                    isVisible: menu.includes('printers')
                },
            ],
        },
    ];

    const visibleMenuItems = getVisibleMenuItems(menuItems);

    return <React.Fragment>{visibleMenuItems}</React.Fragment>;
};
export default Navdata;
